body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-image {
  height: 100vh;
  position: relative;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #c6bfaa;
  font-weight: bolder;
}

body {
  background-image: linear-gradient(
      rgba(39, 57, 65, 0.8),
      rgba(39, 57, 65, 0.8)
    ),
    url(/static/media/bg.a1664e5e.jpg);
  background-attachment: fixed;
  background-size: contain;
  background-repeat: repeat;
}

@media (max-width: 920px) {
  body {
    background-size: contain;
    background-repeat: repeat;
  }
}
hr.divider {
  max-width: 3.25rem;
  border-width: 5px;
  border-color: #c6bfaa;
  margin: auto;
}

.btn {
  color: #8c9c9e !important;
  border-color: #8c9c9e !important;
  background-color: transparent !important;
  height: 50px !important;
  width: 150px !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.btn:hover {
  color: #c6bfaa !important;
  border-color: #c6bfaa !important;
  background-color: #8c9c9e !important;
}

p {
  font-size: 18px;
  font-weight: bold;
}

h2 {
  font-size: 30px !important;
  font-weight: bold !important;
}

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.listImage {
  width: 100%;
  height: 260px !important;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 25px;
  box-shadow: -4px 0px 14px -3px #c6bfaa;
}

